import moment from 'moment'
import numeral from 'numeral'

export default {
  methods: {
    dateFormatter (value) {
      return moment(value).format('Y-MM-DD')
    },

    dateTimeFormatter (value) {
      return moment(value).format('Y-MM-DD H:mm:ss')
    },

    numberFormatter (value) {
      return numeral(value).format('0,0.00')
    }
  }
}
